// import React,{useState} from 'react'

export default function About(props) {

    // const [myStyle,setMyStyle]=useState({
    //     color:'black',
    //     backgroundColor:'white'
    // })
    let myStyle = {
        color: props.Mode === 'dark' ? 'white' : 'black',
        backgroundColor: props.Mode === 'dark' ? '#2a2ab5' : 'white',
    }
    // const setMode=()=>{
    //     if(myStyle.color==='black'){
    //         setMyStyle({color:'white',
    //         backgroundColor:'black',
    //         border:'1px solid white'

    //     })
    //     setBtnText("Enable Light Mode")
    //     }
    //     else{
    //         setMyStyle({color:'black',backgroundColor:'white'})
    //         setBtnText("Enable Dark Mode")
    //     }

    // }
    // const [btnText,setBtnText] = useState('Enable Dark Mode');


    return (
        <div className="container">
            <h1 className='my-3' style={{color:props.Mode==='dark'?'white':'black'}}>About Us</h1>
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"style={myStyle}>
                        <strong>
                            Analyze Your Text
                            </strong>
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body"style={myStyle}>
                            "Analyzing your text is an essential process that involves carefully examining and evaluating the content you have written. It encompasses various aspects, including understanding the main ideas and arguments presented, assessing the overall structure and organization, and evaluating the language and style used. By analyzing your text, one can gain insights into the clarity and coherence of the writing, as well as identify any potential grammatical or punctuation errors. Moreover, delving into the context and purpose behind your text allows for a deeper understanding of its intended message and effectiveness in conveying that message to the intended audience. Whether you are a writer seeking to improve your work or a researcher analyzing data, the act of analyzing your text provides valuable opportunities for refinement and better communication of ideas."
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"style={myStyle}>
                            <strong>
                            Free To Use
                            </strong>
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body"style={myStyle}>
                        These software offerings grant individuals the freedom to download, install, and utilize the program's functionalities without requiring any payment or subscription fees. Free-to-use software is often made available under open-source licenses, which means that users can access the source code, modify it, and distribute their own versions, subject to certain conditions specified in the license agreement. This model fosters collaboration and community-driven development, as developers from around the world can contribute to the improvement and enhancement of the software. Moreover, free-to-use software provides a cost-effective solution for users, making advanced technologies and tools accessible to a wider audience. It has played a pivotal role in driving innovation and democratizing access to technology across various domains, benefitting both individuals and organizations alike.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"style={myStyle}>
                        <strong>    
                            Browser Compatible
                            </strong>
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body"style={myStyle}>
                        With numerous web browsers available, such as Google Chrome, Mozilla Firefox, Microsoft Edge, Safari, and others, each may interpret website code differently, leading to variations in how the content and features are displayed or operated. Ensuring browser compatibility is essential for delivering a seamless and consistent user experience to visitors regardless of the browser they use. Web developers and designers employ various techniques, such as using standardized web technologies like HTML, CSS, and JavaScript, and performing cross-browser testing, to identify and address compatibility issues. By prioritizing browser compatibility, website owners can expand their reach to a broader audience and enhance user satisfaction by offering a smooth browsing experience across different platforms and devices.
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container">
            <button className="btn btn-primary my-3"onClick={setMode}>{btnText}</button>

            </div> */}
        </div >
       
    )
}
