import './App.css';
import Alert from './components/Alert';
import About from './components/About';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import { useState } from 'react';

// import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom'
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const [Mode, setMode] = useState("light")

  const togglemode = () => {
    if (Mode === "dark") {
      setMode('light')
      document.body.style.backgroundColor = "white"
      showAlert("Light mode has been enabled", "success")
      // document.title = "TextUtils - Light Mode"
    }
    else {
      setMode('dark')
      document.body.style.backgroundColor = "#031f58"
      showAlert("Dark mode has been enabled", "success")
      // document.title = "TextUtils - Dark Mode"

    }
  }

  const [alert, setAlert] = useState(null)

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null)
    }, 1500);
  }

  return (

    // <Navbar  title="TextUtils" AboutText="About TextUtils"/>
    <>
      <BrowserRouter>
        <Navbar title="TextUtils" Mode={Mode} togglemode={togglemode} AboutText="About TextUtils" />
        <Alert alert={alert} />
        <div className="container my-3 mx-7">
          <Routes>
            <Route exact path="/"
              element={
                <TextForm heading="Try TextUtils - Word Counter, Character Counter, Remove extra spaces" showAlert={showAlert} Mode={Mode} />
              }
            />
            <Route exact path="/about"
              element={<About  Mode={Mode} />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
