import React, { useState } from 'react'


export default function TextForm(props) {
    const [Text, setText] = useState("");

    const handleUpClick = () => {
        // console.log("Uppercase was clicked "+Text );
        setText(Text.toUpperCase())
        props.showAlert("Converted to UpperCase", "success");

    }
    const handleLoClick = () => {
        // console.log("Uppercase was clicked "+Text );
        setText(Text.toLowerCase())
        props.showAlert("Converted to LowerCase", "success");

    }
    const handleClearClick = () => {
        // console.log("Uppercase was clicked "+Text );
        setText("")
        props.showAlert("Clear the Text", "success");

    }
    const handleCopy = () => {
        navigator.clipboard.writeText(Text);
        props.showAlert("Copied to Clipboard", "success");

    }
    const handleExtraSpaces = () => {
        let newText = Text.split(/[ ]+/);
        setText(newText.join(" "));
        props.showAlert("Removed the Extra Spaces", "success")

    }
    const handleOnChange = (event) => {
        // console.log("On Change");
        setText(event.target.value);
    }
    return (
        <>
            <div className="container" style={{ color: props.Mode === 'dark' ? 'white' : 'black', backgroundColor: props.Mode === 'dark' ? '#031f58' : 'white' }}>
                <h1 className='mb-4'>{props.heading}</h1>
                <div className="mb-3">
                    <textarea className="form-control" placeholder='Enter the Text here' value={Text} onChange={handleOnChange} id="myBox" rows="8" style={{
                        color: props.Mode === 'dark' ? 'white' : 'black', backgroundColor: props.Mode === 'dark' ? '#083e78': 'white' }}></textarea>
                </div>
                <button disabled={Text.length===0} className="btn btn-primary mx-1 my-1" onClick={handleUpClick}>Convert To UpperCase</button>
                <button disabled={Text.length===0} className="btn btn-primary mx-1 my-1" onClick={handleLoClick}>Convert To LowerCase</button>
                <button disabled={Text.length===0} className="btn btn-primary mx-1 my-1" onClick={handleClearClick}>Clear Text</button>
                <button disabled={Text.length===0} className="btn btn-primary mx-1 my-1" onClick={handleCopy}>Copy Text</button>
                <button disabled={Text.length===0} className="btn btn-primary mx-1 my-1" onClick={handleExtraSpaces}>Remove Extra Spaces</button>



            </div>
            <div className="container my-3" style={{ color: props.Mode === 'dark' ? 'white' : 'black' }}>
                <h1>Your Text Summary</h1>
                <p>{Text.split(/\s+/).filter((element) => { return element.length !== 0 }).length} words and {Text.length} characters</p>
                <p>{0.008 * Text.split(" ").filter((element) => { return element.length !== 0 }).length} Minutes To Read</p>
                <h2>Preview</h2>
                <p>{Text.length > 0 ? Text : "Nothing to preview"}</p>

            </div>
        </>
    )
}
